import { env } from 'src/env.mjs';

const basePath = env.NEXT_PUBLIC_API_PATH;

export default {
    THERAPISTS: `${basePath}/api/v1/psychologists`,
    RENEWAL_QUIZZES: `${basePath}/api/v2/renewal_quizzes`,
    RENEWAL_QUIZZES_START_QUIZ: `${basePath}/api/v2/renewal_quizzes/start_quiz`,
    RENEWAL_QUIZZES_SUBMIT_ANSWER: `${basePath}/api/v2/renewal_quizzes/submit_answer`,
    DOCTOR_PLANS: (quizToken: string) =>
        `${basePath}/api/v2/doctor_plans/${quizToken}`,
    USER: (uuid: string) => `${basePath}/api/v2/users/${uuid}`,
    APPLE_LOGIN: `${basePath}/api/v2/tokens/request/apple`,
    GOOGLE_LOGIN: `${basePath}/api/v2/tokens/request/google`,
    LOGIN: `${basePath}/api/v2/tokens/request`,
    PLAN_CONFIGURATION: `${basePath}/api/v2/plans/configuration`,
    PAYMENT: (
        paymentType:
            | 'card_payment'
            | 'initialize_paypal'
            | 'initialize_afterpay'
            | 'initialise_payment_intent'
            | 'finalise_payment_intent',
    ) => `${basePath}/api/v2/payments/${paymentType}`,
    CREATE_ACCOUNT: `${basePath}/api/v2/users`,
    VERIFY_EMAIL: `${basePath}/api/v2/users/verify_email`,
    USER_HEALTH_TRACKING_GET: (
        uuid: string,
        variables: string[] | boolean[] | number[],
    ) =>
        `${basePath}/api/v2/users/${uuid}/health_tracking${variables.length
            ? `?variable[]=${variables.join('&variable[]=')}`
            : ''
        }`,
};
